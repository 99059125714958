import React, { Fragment } from "react";
import { Hero, How, Contact, About } from "../components";

export default function Main() {
  return (
    <Fragment>
      <Hero />
      <How />
      <About />
      <Contact />
    </Fragment>
  );
}
