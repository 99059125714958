import React, { Fragment, useEffect, useRef } from "react";
import Typed from "typed.js";
import { heroIcons } from "../../data/constants";
import HeroButtons from "./HeroButtons";
import { motion } from "framer-motion";
import BlueLaundry from "../../images/blue laundry.png";

export default function Hero() {
  const ty = useRef(null);

  useEffect(() => {
    const typed = new Typed(ty.current, {
      strings: [
        "Located at Mnarani, Kilifi County.",
        "Monday - Saturday.",
        "8.00 am - 7.00 pm.",
        "View our Brochure for more info.",
      ],
      loop: true,
      loopCount: Infinity,
      startDelay: 100,
      typeSpeed: 100,
      backSpeed: 50,
      backDelay: 100,
    });

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy();
    };
  }, []);

  return (
    <Fragment>
      <div
        className="flex flex-col md:flex-row justify-evenly items-center pt-10 ss:mt-[100px] mt-[50px] sm:gap-20 gap-10 relative"
        id="home"
      >
        <div className="flex flex-col gap-2 items-center md:items-start mt-[20px] sm:mt-5">
          <motion.img
            initial={{ opacity: 0, translateX: "-100%" }}
            whileInView={{ opacity: 1, translateX: "0%" }}
            transition={{
              delay: 0.1,
              duration: 0.5,
              type: "spring",
              stiffness: 60,
            }}
            viewport={{ once: true, amount: 0.5 }}
            src={BlueLaundry}
            alt="blue laundry services kilifi"
            className="w-[12rem] sm:w-[18rem] md:w-[20rem] lg:w-[22rem] xl:w-[24rem] mt-12 sm:mt-0"
          />
          <p className="mt-12 mb-5 text-md text-white text-center md:text-start xs:text-start">
            <span
              className=" font-inter text-xxl md:text-xl sm:text-xl text-bold"
              ref={ty}
            />
          </p>
          <motion.p
            initial={{ opacity: 0, translateX: "-50%" }}
            whileInView={{ opacity: 1, translateX: "0%" }}
            transition={{
              delay: 0.2,
              duration: 0.5,
              type: "spring",
              stiffness: 60,
            }}
            viewport={{ once: true, amount: 0.5 }}
            className="text-white font-Comfortaa md:w-[300px] w-[250px] xs:text-[15px] text-[12px] font-bold text-center md:text-start"
          >
            Blue laundry services Mnarani, Kilifi county. We are here to sort
            out your laundry, dry cleaning and ironing needs. The one-stop shop
            for all your laundry needs. We have the best rates and reasonable
            prices in order to make our services accessible to everyone who
            needs them.
            <i className="fa fa-thermometer-quarter" aria-hidden="true"></i>
          </motion.p>
          <div className="flex justify-center md:justify-start md:w-12 xs:w-10 w-7 gap-3 mt-5">
            {heroIcons.map((icon, idx) => (
              <motion.img
                initial={{ opacity: 0, translateY: "-100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: idx * 0.1,
                  duration: 0.5,
                  type: "spring",
                  stiffness: 70,
                }}
                viewport={{ once: true, amount: 0.5 }}
                key={icon.title}
                src={`/images/Hero/${icon.image}.png`}
                alt={icon.title}
                loading="lazy"
                className="cursor-pointer"
              />
            ))}
          </div>
        </div>
        <div>
          <motion.img
            initial={{ opacity: 0, translateX: "50%" }}
            whileInView={{ opacity: 1, translateX: "0%" }}
            transition={{
              delay: 0.2,
              duration: 0.5,
              type: "spring",
              stiffness: 70,
            }}
            viewport={{ once: true, amount: 0.5 }}
            src="/images/Hero/MASTERRR.png"
            alt="blue laundry services kilifi"
            loading="lazy"
            className="md:mx-0 mx-auto w-[300px] md:w-[400px]"
          />
        </div>
      </div>
      <HeroButtons />
    </Fragment>
  );
}
