import React, { Fragment } from "react";
import phoneIcon from "../../src/images/phone.png";
import emailIcon from "../../src/images/email.png";
import locationIcon from "../../src/images/pngwing.com.png";

export default function Footer() {
  const year = new Date().getFullYear();
  return (
    <Fragment>
      <div className=" flex-col font-inter py-1 mt-[50px] bg-gray-600 text-white justify-evenly items-center text-center font-bold text-[13px]">
        <div>
          <div className=" font-inter flex justify-center items-center">
            <img
              src={phoneIcon}
              alt="blue laundry services kilifi phone"
              className="w-[1rem] sm:w-[1rem]"
            />
            <span className="pl-2">0758336165</span>
          </div>
          <div className=" font-inter flex justify-center items-center">
            <img
              src={emailIcon}
              alt="blue laundry services kilifi email"
              className=" w-[1.25rem] sm:w-[1.25rem]"
            />
            <a href="mailto:bluelaundrykilifi@gmail.com" className="underline">
              <span className="pl-2 hover:text-yellow-400">
                bluelaundrykilifi@gmail.com
              </span>
            </a>
          </div>
        </div>
        <div className="font-inter flex justify-center items-center">
          <img
            src={locationIcon}
            alt="blue laundry services kilifi location"
            className="w-[1.25rem] sm:w-[1.25rem]"
          />
          <span className="pl-2 font-inter">Mnarani, Kilifi County/</span>
        </div>
        <div className=" justify-evenly w-full">
          <div className="flex-grow text-center font-bold ">
            Blue Laundry {year} <span className="">©</span> All Rights Reserved.
          </div>
        </div>
        <span className="text-[12px]">
          Design by
          <span className="underline text-yellow-300 hover:text-blue-800 pl-1">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://personal-portfolio-site-tailwindcss.vercel.app/"
            >
              Edwin Munene
            </a>
          </span>
          .
        </span>
      </div>
    </Fragment>
  );
}
