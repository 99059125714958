export const navlinks = [
  {
    tittle: "HOME",
    link: "home",
  },

  {
    tittle: "SERVICES",
    link: "how",
  },
  {
    tittle: "ABOUT US",
    link: "about",
  },
  {
    tittle: "CONTACT",
    link: "contact",
  },
];

export const heroIcons = [
  {
    tittle: "Whatsapp",
    image: "whatsapp",
    link: "",
  },
  {
    tittle: "Twitter",
    image: "twitter",
    link: "https://x.com/BlueLaundryKe",
  },
  {
    tittle: "Facebook",
    image: "facebook",
    link: "",
  },
  
  // {
  //   tittle: "Tiktok",
  //   image: "tiktok",
  //   link: "",
  // },
];

export const herobuttons = [

  {
    tittle: "Monday - Saturday",
    logo: "basket",
    // link: "",
  },
  {
    tittle: "8.00 am - 7.00 pm",
    logo: "duvets",
    // link: "",
  },
  {
    tittle: "Laundry from 6 kg 600/=",
    logo: "suit",
    // link: "",
  },
  {
    tittle: "Extra Kg @ 100/=",
    logo: "curtains",
    // link: "",
  },
  {
    tittle: "Shoe washing from 200/=",
    // logo: "poloniex",
    // link: "",
  },
  {
    tittle: "Ironing/Steaming from 50/= per item",
    // logo: "okxdex",
    // link: "",
  },
];

export const howToBuy = [
  {
    tittle: "General Laundry and Folding",
    image: "basket",
    desc: "We have invested in the state of the art machines and have an experienced team which is always ready to serve you our dear esteemed client.",
  },
  {
    tittle: "Ironing/Steaming and Pressing",
    image: "ironing",
    desc: "We iron every thing and anything from sheets, suits, shirts, trousers, dresses, uniforms, etc. Come the the professionals do it for you.",
  },
  {
    tittle: "Tumble Drying",
    image: "washing_machine",
    desc: "We have new and latest models machines which ensure your items are well cleaned and stored for collection.",
  },
  {
    tittle: "Beddings and Curtains",
    image: "duvets",
    desc: "Our team is the best in town in terms of cleaning dust or stains from your beddings, covers, blankets, duvets, sheets and curtains.",
  },
  {
    tittle: "Shoe Cleaning",
    image: "shoe_cleaning",
    desc: "Our team is the best in town in terms of cleaning dust or stains from your beddings, covers, blankets, duvets, sheets and curtains.",
  },
  {
    tittle: "Dry Cleaning",
    image: "suit",
    desc: "Our team also specializes in baby and children clothes ans uniform. Book a servis with us today",
  },
];



export const contactLinks = [
  { tittle: "Telegram", image: "telegram", link: "" },
  { tittle: "Twitter", image: "twitter", link: "" },
  { tittle: "Discord", image: "discord", link: "" },
];
