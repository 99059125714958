import React, { Fragment, useState, useEffect, useRef } from "react";
import { navlinks } from "../data/constants";
import { motion } from "framer-motion";

export default function Navbar() {
  const [open, setOpen] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const menuRef = useRef(null);

  const handleScroll = (id) => {
    const element = document.getElementById(
      id.toLowerCase().replace(/\s+/g, "")
    );
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setOpen(false); // Close the menu after selecting a route
    }
  };

  const scrollToTop = () => {
    const homeElement = document.getElementById("home");
    if (homeElement) {
      homeElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    const handleScrollEvent = () => {
      if (window.scrollY > 300) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    window.addEventListener("scroll", handleScrollEvent);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("scroll", handleScrollEvent);
    };
  }, []);

  return (
    <Fragment>
      <div className="w-full flex py-3 pt-3 bg-gray-600 justify-evenly items-center fixed top-0 z-10">
        <motion.img
          initial={{ opacity: 0, translateY: "50%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            type: "spring",
            stiffness: 100,
          }}
          viewport={{ once: true, amount: 0.5 }}
          src="/images/nav/SkNav.png"
          alt="blue laundry services kilifi"
          loading="lazy"
          className="h-20 rounded-lg"
        />
        <motion.div
          className="sm:flex hidden justify-between gap-5 text-white font-inter"
          initial={{ opacity: 0, translateY: "100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            type: "spring",
            stiffness: 100,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          {navlinks.map((nav) => (
            <a
              key={nav.tittle}
              className="cursor-pointer"
              href={`#${nav.tittle.toLowerCase().replace(/\s+/g, "")}`}
              onClick={(e) => {
                e.preventDefault();
                handleScroll(nav.tittle);
              }}
            >
              {nav.tittle}
            </a>
          ))}
        </motion.div>
        <motion.div
          className="text-white font-inter py-3 px-5 border rounded-3xl cursor-pointer"
          initial={{ opacity: 0, translateY: "100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.3,
            duration: 0.5,
            type: "spring",
            stiffness: 100,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          BOOK NOW
        </motion.div>

        <img
          src={`/images/nav/${open ? "cross" : "select"}.svg`}
          className="cursor-pointer px-5 sm:hidden flex"
          alt="ham"
          onClick={() => {
            setOpen((prev) => !prev);
          }}
        />
        {open && (
          <motion.div
            ref={menuRef}
            className="absolute w-full text-white font-inter  bg-navBg mt-[320px] p-5 flex flex-col gap-5 -z-10"
            initial={{ opacity: 0, translateY: "-100%" }}
            whileInView={{ opacity: 1, translateY: "0%" }}
            transition={{
              delay: 0.1,
              duration: 0.1,
              type: "spring",
              stiffness: 100,
              damping: 14,
            }}
            viewport={{ once: true, amount: 0.5 }}
          >
            {navlinks.map((nav) => (
              <motion.a
                initial={{ opacity: 0, translateY: "-100%" }}
                whileInView={{ opacity: 1, translateY: "0%" }}
                transition={{
                  delay: 0.1,
                  duration: 0.1,
                  type: "spring",
                  stiffness: 100,
                }}
                viewport={{ once: true, amount: 0.5 }}
                key={nav.tittle}
                className="cursor-pointer text-center"
                href={`#${nav.tittle.toLowerCase().replace(/\s+/g, "")}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleScroll(nav.tittle);
                }}
              >
                {nav.tittle}
              </motion.a>
            ))}
          </motion.div>
        )}
      </div>

      {showScrollButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-9 right-1 bg-yellow-400 text-black p-3 rounded-full shadow-lg hover:bg-blue-500 hover:text-white transition duration-300 z-50 font-Comfortaa font-bold flex justify-center items-center"
        >
          ↑ Top
        </button>
      )}
    </Fragment>
  );
}
