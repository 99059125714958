import React, { Fragment } from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import ContactImg from "../../../src/images/Get in touch-amico.png";

export default function Contact() {
  const {
    register,
    trigger,
    formState: { errors },
  } = useForm();

  const onSubmit = async (e) => {
    const isValid = await trigger();
    if (!isValid) {
      e.preventDefault();
    }
  };

  return (
    <Fragment>
      <div className="mt-[150px]" id="contact">
        <motion.h1
          className="font-inter text-white font-bold lg:w-[30%] w-[80%] mx-auto text-[45px] sm:py-[30px] text-center"
          initial={{ opacity: 0, translateY: "100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.1,
            duration: 0.5,
            type: "spring",
            stiffness: 60,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          Contact Us
        </motion.h1>

        <div className="flex flex-col lg:flex-row lg:px-12 justify-evenly items-center">
          <motion.img
            initial={{ opacity: 0, scale: 0 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{
              delay: 0.3,
              duration: 0.5,
              type: "spring",
              stiffness: 60,
            }}
            viewport={{ once: true, amount: 0.5 }}
            src={ContactImg}
            alt="blue laundry services kilifi"
            className="mx-auto w-[300px] lg:w-1/3 lg:order-1"
          />
          <div className="w-[80%] lg:w-1/3 mt-10 lg:mt-0 lg:ml-10 font-inter">
            <form
              target="_blank"
              onSubmit={onSubmit}
              action="https://formsubmit.co/d69309e5c3272e4d2d66937d62b4e99c"
              method="POST"
            >
              <input
                className="w-full bg-white font-semibold text-black p-3 contact-input"
                type="text"
                placeholder="Name"
                {...register("name", {
                  required: true,
                  maxLength: 100,
                })}
              />
              {errors.name && (
                <p className="text-red-600 mt-1 contact-input">
                  {errors.name.type === "required" && "This field is required."}
                  {errors.name.type === "maxLength" &&
                    "Max length is 100 char."}
                </p>
              )}
              <input
                style={{ marginTop: "1rem" }}
                className="w-full bg-white font-semibold text-black p-3 contact-input"
                type="text"
                placeholder="Subject"
                {...register("subject", {
                  required: true,
                  maxLength: 100,
                })}
              />
              {errors.subject && (
                <p className="text-red-600 mt-1 contact-input">
                  {errors.subject.type === "required" &&
                    "This field is required."}
                  {errors.subject.type === "maxLength" &&
                    "Max length is 100 char."}
                </p>
              )}
              <input
                className="w-full bg-white font-semibold text-black p-3 mt-5 contact-input"
                type="text"
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email && (
                <p className="text-red-600 mt-1 contact-input">
                  {errors.email.type === "required" &&
                    "This field is required."}
                  {errors.email.type === "pattern" && "Invalid email address."}
                </p>
              )}
              <textarea
                className="w-full bg-white font-semibold text-black p-3 mt-5 contact-input"
                name="message"
                placeholder="Message"
                rows="4"
                cols="50"
                {...register("message", {
                  required: true,
                  maxLength: 2000,
                })}
              />
              {errors.message && (
                <p className="text-red-600 mt-1 contact-input">
                  {errors.message.type === "required" &&
                    "This field is required."}
                  {errors.message.type === "maxLength" &&
                    "Max length is 2000 char."}
                </p>
              )}
              <button
                className="bg-yellow-400 text-black py-3 px-7 font-semibold transition duration-500 rounded-full mt-5 mx-auto flex justify-center items-center hover:bg-indigo-600 hover:text-white"
                type="submit"
              >
                Send
              </button>
            </form>
          </div>
        </div>

        <motion.p
          className="mt-10 text-white font-black mx-auto font-Comfortaa ss:w-[60%] w-[80%] text-[15px] text-center"
          initial={{ opacity: 0, translateY: "100px" }}
          whileInView={{ opacity: 1, translateY: "0px" }}
          transition={{
            delay: 0.2,
            duration: 0.5,
            type: "spring",
            stiffness: 100,
          }}
          viewport={{ once: true, amount: 0.5 }}
        ></motion.p>
      </div>
    </Fragment>
  );
}
