import React, { Fragment } from "react";
import { motion } from "framer-motion";

export default function About() {
  return (
    <Fragment>
      <div
        className="text-white text-center mx-auto mt-[200px] flex sm:flex-row flex-col justify-center items-center leading-3 sm:gap-20 gap-5"
        id="aboutus"
      >
        <div className="flex flex-col text-white">
          <motion.h1
            className="font-inter text-white font-bold lg:w-[30%] w-[80%] mx-auto text-[45px] sm:py-[10px] text-center p-[1rem]"
            initial={{ opacity: 0, translateY: "100%" }}
            whileInView={{ opacity: 1, translateY: "0%" }}
            transition={{
              delay: 0.1,
              duration: 0.2,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ once: true, amount: 0.5 }}
          >
            ABOUT US
          </motion.h1>
          <br />
          <br />
          <br />
          <motion.p
            className="w-[60%] sm:w-[80%] font-Comfortaa text-white mt-5  text-center mx-auto font-bold leading-5 lg:px-12"
            // initial={{ opacity: 0, translateY: "100%" }}
            // whileInView={{ opacity: 1, translateY: "0%" }}
            // transition={{
            //   delay: 0.1,
            //   duration: 0.5,
            //   type: "spring",
            //   stiffness: 60,
            // }}
            // viewport={{ once: true, amount: 0.5 }}
          >
            <p className="leading-7 text-center">
              Welcome to Blue Laundry, your trusted laundry service in Mnarani,
              Kilifi County. We transform the mundane chore of laundry into a
              delightful experience, offering premium care for all your
              garments.
              <p></p>
              <p className="leading-7 text-center">
                Our dedicated team uses state-of-the-art equipment and
                eco-friendly detergents to ensure pristine results. We provide
                convenient, flexible services with delivery within Kilifi
                County.
              </p>
            </p>
            <p className="leading-7 text-center">
              Our personalized care guarantees your complete satisfaction,
              treating your clothes as if they were our own.From everyday
              laundry and dry cleaning to specialty items and bedding, we handle
              it all. Experience the Blue Laundry difference today!
            </p>
            <br />
            <strong>
              <p className="text-center mb-3">
                Call, Text/Whatsapp or Email for Bookings.
              </p>
            </strong>
            <p className="text-center flex flex-col ">
              <span>Phone :</span> <span>0758 336 165</span>
            </p>
            <br />
            <p className="text-center flex flex-col ">
              <span>Email :</span>
              <span>
                <a
                  href="mailto:bluelaundrykilifi@gmail.com"
                  className="underline hover:text-yellow-400"
                >
                  bluelaundrykilifi@gmail.com
                </a>
              </span>
            </p>
          </motion.p>

          <br />
          <motion.div
            className="text-white font-inter mx-auto py-2 mt-5 flex justify-center items-center  px-5 border w-[10rem] h-10 rounded-3xl cursor-pointer "
            initial={{ opacity: 0, translateY: "100%" }}
            whileInView={{ opacity: 1, translateY: "0%" }}
            transition={{
              delay: 0.1,
              duration: 0.5,
              type: "spring",
              stiffness: 100,
            }}
            viewport={{ once: true, amount: 0.5 }}
          >
            BOOK NOW
          </motion.div>
        </div>
      </div>
      <br />
      <div
        className="text-white flex flex-col justify-center items-center mt-10"
        id="LOCATION"
      >
        <motion.h2
          className="font-inter text-white font-bold text-[35px] text-center"
          initial={{ opacity: 0, translateY: "100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.3,
            duration: 0.5,
            type: "spring",
            stiffness: 100,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          Our Location
        </motion.h2>
        <motion.p
          className="w-[50%] font-Comfortaa font-bold text-white mt-5 text-center  leading-5"
          initial={{ opacity: 0, translateY: "100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.3,
            duration: 0.5,
            type: "spring",
            stiffness: 60,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          <p>Visit us:</p> <br />
          <p className=" leading-7">
            Join us we are just 40 min from Mnarani junction, Kilifi County, and
            discover why we're the preferred choice for impeccable laundry
            services.
          </p>
          <br />
          <p> Find us on Google Maps:</p>
        </motion.p>
        <motion.div
          className="w-2/3 h-64 mt-5 mx-auto flex justify-center items-center"
          initial={{ opacity: 0, translateY: "100%" }}
          whileInView={{ opacity: 1, translateY: "0%" }}
          transition={{
            delay: 0.4,
            duration: 0.5,
            type: "spring",
            stiffness: 60,
          }}
          viewport={{ once: true, amount: 0.5 }}
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d126799.30625527297!2d39.81407740371046!3d-3.67160364110871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1840128355555555%3A0x58d55ecff85e82e3!2sMnarani%2C%20Kilifi%20County%2C%20Kenya!5e0!3m2!1sen!2sus!4v1625587028738!5m2!1sen!2sus"
            width="80%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="blue laundry services kilifi location"
          />
        </motion.div>
      </div>
    </Fragment>
  );
}
